import React from 'react';
import { default as MuiAccordion } from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import accordionStyles from './AccordionStyles';

type AccordionProps = {
  className: string;
}

const StyledAccordion = styled('section')<AccordionProps>(({ theme }) => accordionStyles);

const Accordion: React.FC<AccordionProps> = (props) => {
  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const { className } = props;

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <StyledAccordion className={'mx-auto ' + className}>
      <MuiAccordion defaultExpanded expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Flexibilidad operativa
        </AccordionSummary>
        <AccordionDetails>
          Rentar cajas secas proporciona flexibilidad en función de las necesidades cambiantes del negocio. Puedes ajustar el tamaño y la cantidad de cajas secas que necesitas según la demanda, sin comprometerte a largo plazo
        </AccordionDetails>
      </MuiAccordion>
      <MuiAccordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          Mantenimiento incluido
        </AccordionSummary>
        <AccordionDetails>
          Esto te dará tranquilidad ya que no tendrás que gestionar el mantenimiento y tendrás la garantía que las cajas secas estén en condiciones óptimas
        </AccordionDetails>
      </MuiAccordion>
      <MuiAccordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          Costos iniciales más bajos
        </AccordionSummary>
        <AccordionDetails>
          La compra de una caja seca puede requerir una inversión inicial muy grande, por lo que rentarla protegerá a otras áreas de tu negocio
        </AccordionDetails>
      </MuiAccordion>
      <MuiAccordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          Evitar la depreciación
        </AccordionSummary>
        <AccordionDetails>
          La propiedad de equipos conlleva la depreciación del valor con el tiempo. Al rentar cajas secas, te libras de este riesgo, ya que no eres el propietario
        </AccordionDetails>
      </MuiAccordion>
      <MuiAccordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          Adaptabilidad a la demanda estacional
        </AccordionSummary>
        <AccordionDetails>
          Si tu negocio experimenta cambios estacionales en la demanda, el alquiler de cajas secas te permite ajustar fácilmente la capacidad de tu flota según sea necesario
        </AccordionDetails>
      </MuiAccordion>
      <MuiAccordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          Renovación de flota más fácil
        </AccordionSummary>
        <AccordionDetails>
          Al rentar cajas secas, puedes tener acceso a unidades más nuevas y eficientes sin tener que preocuparte por vender o desechar equipos antiguos  
        </AccordionDetails>
      </MuiAccordion>
    </StyledAccordion>
  );
}

export default Accordion;