import { Theme } from "@mui/material";
import { colors, hoverColors } from "../../constants/colors";

const baseColors = {
  white: '#fff',
  black: '#212121'
}

const styles = {
  textTransform: 'none',
  backgroundColor: colors.primary,
  padding: '8px 2rem !important',
  fontWeight: 400,
  fontSize: '1rem',
  borderRadius: '10px 10px 10px 10px',
  border: `2px solid ${colors.primary}`,
  color: baseColors.white,
  '&:hover': {
    backgroundColor: hoverColors.primary,
    border: `2px solid ${hoverColors.primary}`
  }
}

const buttonStyles: any = (theme?: Theme) => styles;

export default buttonStyles;