import { Theme } from "@mui/material";
import { colors, hoverColors } from "../../constants/colors";

const styles = {
  fontWeight: 300,
  '& .footer-links-section': {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
    padding: '3rem 4rem',
    gap: '1.5rem',
  },
  '& .footer-link-title': {
    color: colors.white,
    marginBottom: '1rem',
    fontWeight: 400,
    fontSize: '16px'
  },
  '& .footer-text': {
    color: colors.white,
    marginBottom: '0.5rem',
    fontSize: '12px'
  },
  '& .footer-link': {
    color: colors.white,
    marginBottom: '0.5rem',
    listStyle: 'none',
    cursor: 'pointer',
    fontSize: '12px',
    '&:hover': {
      color: hoverColors.primary
    }
  },
  '& .footer-policy-section': {
    display: 'grid',
    gap: '2.5rem',
    textAlign: 'center',
    paddingTop: '0.5rem',
    paddingBottom: '2rem',
    color: colors.white,
    gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
  },
  '& .footer-media-button': {
    padding: '0.5rem',
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: '9999px',
    margin: '0.75rem 0',
    transition: '0.1s ease-in-out',
    '&:hover': {
      backgroundColor: hoverColors.primary,
      color: colors.white
    }
  },
  ' span': {
    fontSize: '12px'
  },
  ' ul': {
    borderLeft: '2px solid ' + colors.primary,
  },
  '@media (min-width: 640px)': {
    padding: '3rem 0 1rem 0',
    '& .footer-links-section': {
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      paddingTop: '2rem',
      paddingBottom: '2rem',
    },
    '& .footer-policy-section': {
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    }
  },
  '@media (min-width: 768px)': {
  },
  '@media (min-width: 1024px)':{
    '& .footer-links-section': {
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
      marginTop: '2rem',
      marginBottom: '2rem',
    },
    '& .footer-policy-section': {
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    }
  }
};

const footerStyles: any = (theme?: Theme) => styles;

export default footerStyles;