import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import findUsStyles from './FindUsStyles';
import findUsBg from '../../images/location-background.png';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Button from "../Button/Button";
import Input from "../Input/Input";

type FooterProps = {

};

const StyledFindUs = styled('section')(({ theme }) => findUsStyles);

const FindUs: React.FC<FooterProps> = () => {
  const [ zipCode, setZipCode ] = useState('')

  const handleInputChange = (event: any) => {
    setZipCode(event.target.value);
  }

  return (
    <StyledFindUs className="relative h-[25rem] lg:h-[20rem]">
      <img className="h-full w-full object-cover" src={findUsBg} />
      <div className="absolute top-0 right-0 left-0 bottom-0 flex justify-center items-center flex-col mx-4 lg:flex-row">
        <LocationOnIcon className="lg:mt-6"/>
        <div className="flex flex-col justify-center items-center w-full lg:w-fit lg:mx-6 lg:mb-6">
          <div className="text-xl font-bold text-white mt-2 mb-4 lg:mb-2">Encuentra una sucursal</div>
          <Input variant="dark" label="Código Postal" fullWidth className="max-w-[20rem] lg:w-[20rem]" onChange={handleInputChange} value={zipCode}/>
        </div>
        <Button href={`https://www.google.com/maps/dir/?api=1&origin=${zipCode}&destination=19.900293,-98.605517`} target="_blank" className="mt-6 lg:mt-6">Ir a Google Maps</Button>
      </div>
    </StyledFindUs>
  );
};

export default FindUs;