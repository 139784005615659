export const colors = {
  primary: '#da291c',
  secondary: '#474747',
  tertiary: '#f5f5f5',
  black: '#212121',
  white: '#fdfdfd'
}

export const hoverColors = {
  primary: '#b12015'
}