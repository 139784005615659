import React from 'react';
import { colors } from '../../constants/colors';

type SeparatorProps = {
  className?: string;
}

const Separator: React.FC<SeparatorProps> = (props) => {
  const { className } = props;

  return (
    <div className={'flex justify-center ' + className}>
      <div className="w-14 h-1" style={{ backgroundColor: colors.primary }} />
    </div>
  )
}

export default Separator;