import { Theme } from "@mui/material";
import { colors } from "../../constants/colors";

const styles = {
  backgroundColor: colors.tertiary,
  position: 'relative',
  overflow: 'hidden',
  '::before': {
    content: '""',
    position: 'absolute',
    width: '8rem',
    borderTop: '8px solid ' + colors.secondary,
    borderBottom: '1rem solid ' + colors.primary,
    top: '0',
    left: '0',
    backgroundColor: colors.primary,
    transform: 'skewY(-20deg)'
  }
};

const ctaStyles: any = (theme?: Theme) => styles;

export default ctaStyles;