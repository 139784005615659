import React from 'react';
//@ts-ignore
import GoogleMapReact from 'google-map-react';
import ContactForm from '../ContactForm/ContactForm';
import { colors } from '../../constants/colors';
import './Map.css';

type MapProps = {
}

const Map:React.FC<MapProps> = (props) => {
  const defaultProps = {
    center: {
      lat: 19.900108,
      lng: -98.605482
    },
    zoom: 15
  };

  const Marker:React.FC<any> = (props) => {
    return <a target="_blank" href="https://www.google.com/maps/place/19.900293,-98.605517">
      <div className="pin"></div>
      <div className="pulse"></div>
    </a>
  }
  

  return (
    <section className='flex flex-col lg:flex-row overflow-hidden' style={{ backgroundColor: colors.secondary }}>
      <div className='map-separator z-10 relative flex justify-center '>
        <div className="container w-full lg:pt-[5rem] lg:w-[35rem]">
          <div className="p-8 pb-16">
            <ContactForm></ContactForm>
          </div>
        </div>
      </div>
      <div className='w-full h-[40rem] lg:h-[48rem]'>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyCOHwFFRqzFaAZXDCLeGQMZhcl2PS77fAA" }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          <Marker lat={defaultProps.center.lat} lng={defaultProps.center.lng} />
        </GoogleMapReact>
      </div>
    </section>
  )
}


export default Map;