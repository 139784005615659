import { Theme } from "@mui/material";
import { colors, hoverColors } from "../../constants/colors";

const styles = {
  backgroundColor: 'rgb(245 245 245)',
  marginTop: '1rem',
  marginBottom: '1rem',
  display: 'flex',
  width: '70%',
  marginLeft: '1rem',
  marginRight: '1rem',
  borderRadius: '20px',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2rem',
  flexDirection: 'column',
  border: '1px solid white',
  transition: 'border 0.5s',
  '&:hover':{
    border: `1px solid ${colors.primary}`
  },
  '& .option': {
    backgroundColor: 'transparent',
    width: '130px',
    height: '130px',
    padding: '4rem 2rem',
    margin: '0 auto',
    transition: 'transform 0.2s ease-in-out, color 0.2s ease-in-out',
    '&.hover-class': {
      transform: 'scale(1.2)',
      color: colors.primary
    }
  },
  '& .option-card-title': {
    fontSize: '1.5rem',
    fontWeight: '600',
    marginBottom: '1.8rem',
    marginTop: '1.8rem',
    textAlign: 'center',
    color: colors.black
  },
  '& .MuiSvgIcon-root': {
    color: colors.black
  },
  '& .option-card-subtitle': {
    textAlign: 'center',
    color: colors.black,
    fontWeight: '300',
    marginBottom: '2rem'
  },
  '@media (min-width: 640px)': {
    width: '20rem'
  },
  '@media (min-width: 768px)': {
    width: '20rem',
  },
  '@media (min-width: 1024px)':{
    marginTop: '4rem',
    marginBottom: '1rem',
  }
};

const optionsStyles: any = (theme?: Theme) => styles;

export default optionsStyles;