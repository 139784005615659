import { Theme } from "@mui/material";
import { colors, hoverColors } from "../../constants/colors";

const styles = {
  dark: {
    'input:-webkit-autofill': {
      
    },
    '& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.white
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: colors.white
    },
    '& .MuiInputBase-root': {
      backgroundColor: '#696969',
      '&:autofill': {
        backgroundColor: 'red'
      },
      '&.Mui-focused': {
        backgroundColor: '#777'
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.white
      },
      ':hover': {
        backgroundColor: '#888'
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#bbb'
      },
      '&::after': {
        borderBottom: '2px solid ' + colors.primary
      }
    },
    '& .MuiFormLabel-root': {
      color: colors.white
    },
    '& .MuiOutlinedInput-input': {
      color: colors.white
    },
    '& .MuiInputBase-input': {
      color: colors.white,
      '&:-webkit-autofill':{
        '-webkit-box-shadow': '0 0 0px 1000px #939393 inset',
        '-webkit-text-fill-color': 'white',
        transition: 'background-color 5000s ease-in-out 0s'
      }
    },
  },
  light: {
    '& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.black
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: colors.black
    }
  }
}

const inputStyles: any = (variant: 'dark' | 'light', theme?: Theme) => (styles[variant]);

export default inputStyles;