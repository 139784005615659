import { Theme } from "@mui/material";
import { colors, hoverColors } from '../../constants/colors';

const styles = {
  backgroundColor: 'transparent',
  position: 'fixed',
  boxShadow: 'none',
  zIndex: 1000,
  color: colors.white,
  borderBottom: `1px solid rgba(255,255,255,.5)`,
  transition:  '0.32s ease-in-out',
  '& .MuiButtonBase-root': {
    textTransform: 'none'
  },
  '& .MuiButtonBase-root#navbar-menu-button': {
    fontWeight: 300,
    color: colors.white,
    '&:hover': {
      backgroundColor: hoverColors.primary,
      color: colors.white
    }
  },
  '& #navbar-logo-container': {
    marginRight: '2rem',
    fontWeight: 500,
    color: colors.white,
    fontFamily: 'IndustryTest',
    fontSize: '1rem',
    textDecoration: 'none',
  },
  '.MuiBox-root > a': {
    textShadow: '0 0 5px #000'
  },
  '&.scrolled': {
    backgroundColor: 'hsl(0, 0%, 99%, 1)',
    boxShadow: '0 0 28px hsla(0,0%,50%,.5)',
    padding: '3px 0',
    '& .MuiButtonBase-root#navbar-menu-button': {
      color: colors.black,
      '&:hover': {
        color: colors.white
      }
    },
    '& #navbar-logo-container': {
      color: colors.black
    },
    '& .MuiSvgIcon-root': {
      color: colors.black
    },
    '& .MuiBox-root > a':{
      textShadow: 'none'
    }
  },
  '@media (min-width: 370px)': {
    '& #navbar-logo-container': {
      fontSize: '1.5rem'
    }
  }
};

const navbarStyles: any = (theme?: Theme) => styles;

export default navbarStyles;