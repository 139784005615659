import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import heroStyles from './HeroStyles';
import dryVanVideo from '../../videos/gm-final-video.mp4';
import Button from "../Button/Button";

type HeroProps = {

};

const StyledHero = styled('div')(({ theme }) => heroStyles());

const Hero: React.FC<HeroProps> = () => {
  const [ scrolled, setScrolled ] = useState(['', '', '']);

  useEffect(() => {
    setTimeout(() => {
      setScrolled(['animate__fadeInUp', '', '']);
    }, 400)
    setTimeout(() => {
      setScrolled(['animate__fadeInUp', 'animate__fadeInUp', '']);
    }, 700)
    setTimeout(() => {
      setScrolled(['animate__fadeInUp', 'animate__fadeInUp', 'animate__fadeInUp']);
    }, 1000)
  }, [])

  return (
    <StyledHero>
      <video className="hero-video" src={dryVanVideo} autoPlay loop muted />
      <div className="hero-container">
        <div className="hero-text">
          <div className={`hero-title animate__animated ${scrolled[0]}`}>
            <span>RENTAR ES </span><br/><span className="hero-highlighted-text"> MAS BARATO</span><span> QUE COMPRAR</span>
          </div>
          <div className={`hero-subtitle animate__animated ${scrolled[1]}`}>
            Nos dedicamos a proporcionar soluciones logísticas confiables
            para el transporte y almacenamiento de mercancías, sin necesidad
            de que gastes mucho dinero.
          </div>
          <Button href="/location" className={`animate__animated ${scrolled[2]}`}>
            Quiero ser cliente
          </Button>  
        </div>
      </div>
    </StyledHero>
  );
};

export default Hero;