import { Theme } from "@mui/material";
import { colors, hoverColors } from "../../constants/colors";

const styles = {
  backgroundColor: colors.white,
  height: '110vh',
  width: '100%',
  position: 'relative',
  '& .hero-video': {
    height: '100%',
    position: 'absolute',
    objectFit: 'cover',
    width: '100%',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0'
  },
  '& .hero-container': {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    backgroundColor: 'rgba(0,0,0,0.6)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  '& .hero-text': {
    maxWidth: '768px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  '& .hero-title': {
    fontSize: '2rem',
    lineHeight: '2.875rem',
    fontWeight: '400',
    textAlign: 'center',
    margin: '0 1rem 3rem 1rem',
    color: colors.white
  },
  '& .hero-title *': {
    fontFamily: 'IndustryTest'
  },
  '& .hero-subtitle': {
    fontWeight: '300',
    fontSize: '1rem',
    textAlign: 'center',
    marginBottom: '3rem',
    color: colors.white,
    width: '80%'
  },
  '& .hero-highlighted-text': {
    color: colors.primary,
    textShadow: '0 0 8px #333'
  },
  '& .animate__animated': {
    opacity: 0
  },
  '& .animate__fadeInUp': {
    opacity: 1
  },
  '@media (min-width: 640px)': {
    '& .hero-title': {
      fontSize: '4rem',
      lineHeight: '4rem',
      fontWeight: '500'
    },
    '& .hero-subtitle': {
      fontWeight: '300',
      fontSize: '1rem',
      width: '70%'
    }
  },
  '@media (min-width: 768px)': {
    '& .hero-title': {
      fontSize: '5rem',
      lineHeight: '5rem'
    },
    '& .hero-subtitle': {
      fontWeight: '300',
      fontSize: '1rem',
      width: '70%'
    }
  },
  '@media (min-width: 1024px)':{
    '& .hero-title': {
      fontWeight: '600',
      fontSize: '3.5rem',
      lineHeight: '4rem',
      letterSpacing: '5px'
    },
    '& .hero-subtitle': {
      fontWeight: '300',
      fontSize: '1.25rem'
    }
  },
  '@media (min-width: 1324px)':{
    '& .hero-title': {
      fontWeight: '600',
      fontSize: '5rem',
      lineHeight: '6rem',
      letterSpacing: '5px'
    },
    '& .hero-subtitle': {
      fontWeight: '300',
      fontSize: '1.25rem'
    }
  }
};

const heroStyles: any = (theme?: Theme) => styles;

export default heroStyles;