import React, { useCallback } from 'react';
import { default as MuiInput, TextFieldProps as MuiInputProps } from '@mui/material/TextField';

import { styled } from '@mui/material/styles';
import inputStyles from './InputStyles';

type InputProps = Omit<MuiInputProps, 'variant'> & {
  variant: 'dark' | 'light'
}


const Input:React.FC<InputProps> = (props: InputProps) => {
  const { variant, ...muiProps } = props;
  
  const StyledInput = useCallback(styled(MuiInput)<MuiInputProps>(({ theme }) => inputStyles(variant)), [variant]);

  return (
    <StyledInput variant='filled' autoComplete='on' {...muiProps} />
  )
}

export default Input;