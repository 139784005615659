import React from 'react';
import Separator from '../Separator/Separator';

type BenefitsCardsProps = {
  primaryText: string;
  secondaryText: string;
}

const BenefitsCard: React.FC<BenefitsCardsProps> = (props) => {
  const { primaryText, secondaryText } = props;
  return (
    <div id="benefit-card" className={`flex flex-col items-center p-10 w-[12rem] mx-3 my-6 rounded-xl bg-[#f5f5f5] ${'height'}`}>
      <div className="font-semibold text-2xl text-center mb-6">{primaryText}</div>
      <Separator />
      <div className="font-medium text-center mt-6">{secondaryText}</div>
    </div>
  )
}

export default BenefitsCard;