import React, { Fragment } from 'react'
import { styled } from '@mui/material/styles';
import BenefitsCard from './BenefitCard';
import benefitsStyles from './BenefitsStyles';
import Separator from '../Separator/Separator';
import Accordion from '../Accordion/Accordion';
import { ReactComponent as FlexibilidadSVG } from '../../icons/Flexibility.svg';
import { colors } from '../../constants/colors';
import './Benefits.css';

type BenefitsProps = {}

const StyledBenefits = styled('section')<BenefitsProps>(({ theme }) => benefitsStyles);

const Benefits: React.FC<BenefitsProps> = (props) => {
  return (
    <Fragment>
      <section className='relative h-40'>
        <div className="skewed"></div>
      </section>
      <StyledBenefits className="flex items-center justify-center 2rem lg:pt-[3rem] lg:pb-[6rem]">
        <div className="container">
          <div className="flex flex-col lg:flex-row">
            <div className="static lg:sticky top-0 px-8 lg:w-[45%] h-fit pt-[10rem] pb-[5rem]">
              <div className="flex justify-center items-center text-center rounded-4xl text-3xl font-semibold text-black">
                ¿Por qué rentar es mejor?
              </div>
              <Separator className="mt-10 mb-2"/>
              <div className="text-center px-6 pt-6 text-black max-w-[30rem] mx-auto">
                Aquí hay algunas razones por las cuales nuestros clientes prefieren rentar con nosotros en lugar de comprar
              </div>
            </div>
            <div className="hidden lg:flex items-center justify-between lg:pt-[5rem]">
              <div id="card-column-1" className="w-1/2">
                <BenefitsCard
                  primaryText="Flexibilidad operativa"
                  secondaryText="Rentar cajas secas proporciona flexibilidad en función de las necesidades cambiantes del negocio. Puedes ajustar el tamaño y la cantidad de cajas secas que necesitas según la demanda, sin comprometerte a largo plazo"
                />
                <BenefitsCard
                  primaryText="Costos iniciales más bajos"
                  secondaryText="La compra de una caja seca puede requerir una inversión inicial muy grande, por lo que rentarla protegerá a otras áreas de tu negocio"
                />
                <BenefitsCard
                  primaryText="Adaptabilidad a la demanda estacional"
                  secondaryText="Si tu negocio experimenta cambios estacionales en la demanda, el alquiler de cajas secas te permite ajustar fácilmente la capacidad de tu flota según sea necesario"
                />
              </div>
              <div id="card-column-2" className="h-full">
                <BenefitsCard
                  primaryText="Mantenimiento incluido"
                  secondaryText="Esto te dará tranquilidad ya que no tendrás que gestionar el mantenimiento y tendrás la garantía que las cajas secas estén en condiciones óptimas"
                />
                <BenefitsCard
                  primaryText="Evitar la depreciación"
                  secondaryText="La propiedad de equipos conlleva la depreciación del valor con el tiempo. Al rentar cajas secas, te libras de este riesgo, ya que no eres el propietario"
                />
                <BenefitsCard
                  primaryText="Renovación de flota más fácil"
                  secondaryText="Al rentar cajas secas, puedes tener acceso a unidades más nuevas y eficientes sin tener que preocuparte por vender o desechar equipos antiguos"
                />
              </div>
            </div>
            <Accordion className="lg:hidden"/>
          </div>
        </div>
      </StyledBenefits>
    </Fragment>
  )
}

export default Benefits;