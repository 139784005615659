import React, { useState } from 'react';
import { Grid, Typography, Snackbar, Alert } from '@mui/material';
import Input from '../Input/Input';
import Button from '../Button/Button';
import ReactWhatsapp from 'react-whatsapp';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

type ContactFormProps = {
};

const ContactForm:React.FC<ContactFormProps> = () => {
  const [ params, setParams ] = useState({
    name: '',
    lastName: '',
    email: '',
    message: '',
    phoneNumber: ''
  });
  const [ openSuccessMsg, setOpenSuccessMsg ] = useState(false);
  const [ openErrorMsg, setOpenErrorMsg ] = useState(false);

  const sendRequest = (e: any) => {
    e.preventDefault();
    
    fetch(`https://9bodlpaw9k.execute-api.us-east-1.amazonaws.com/staging?name=${params.name}&lastName=${params.lastName}&email=${params.email}&phoneNumber=${params.phoneNumber}&message=${params.message}`)
      .then((response) => response.json())
      .then((data) => {
        setParams({
          name: '',
          lastName: '',
          email: '',
          message: '',
          phoneNumber: ''
        });
        setOpenSuccessMsg(true);
      })
      .catch((err) => {
        setOpenErrorMsg(true);
        console.log(err.message);
      });
  }

  const onNameChange = (event: any) => {
    setParams({
      ...params,
      name: event.target.value
    })
  };

  const onLastNameChange = (event: any) => {
    setParams({
      ...params,
      lastName: event.target.value
    })
  };

  const onEmailChange = (event: any) => {
    setParams({
      ...params,
      email: event.target.value
    })
  };

  const onMessageChange = (event: any) => {
    setParams({
      ...params,
      message: event.target.value
    })
  };

  const onPhoneNumberChange = (event: any) => {
    setParams({
      ...params,
      phoneNumber: event.target.value
    })
  };

  const handleCloseSuccessMsg = () => {
    setOpenSuccessMsg(false);
  };

  const handleCloseErrorMsg = () => {
    setOpenErrorMsg(false);
  };


  return (
    <div className='mt-16'> 
      <Snackbar open={openSuccessMsg} autoHideDuration={6000} onClose={handleCloseSuccessMsg}>
        <Alert onClose={handleCloseSuccessMsg} severity="success" variant="filled">
          Tu mensaje ha sido enviado exitosamente!
        </Alert>
      </Snackbar>
      <Snackbar open={openErrorMsg} autoHideDuration={6000} onClose={handleCloseErrorMsg}>
        <Alert onClose={handleCloseErrorMsg} severity="error" variant="filled">
          Se ha producido un error
        </Alert>
      </Snackbar>
      <Grid>
        <Typography gutterBottom variant="h5" className="text-white text-4xl font-semibold text-center">
          Contáctenos
        </Typography> 
        <Typography variant="body2" color="textSecondary" component="p" gutterBottom className="text-white font-medium text-center mt-5 mb-10">
          Envíe un mensaje y nuestro equipo se contactará con usted.
        </Typography> 
        <form onSubmit={sendRequest}>
          <Grid container spacing={1}>
            <Grid xs={12} sm={6} item>
              <Input variant="dark" id="name" label="Nombre" fullWidth required value={params.name} onChange={onNameChange} />
            </Grid>
            <Grid xs={12} sm={6} item>
              <Input variant="dark" id="lastName" label="Apellido" fullWidth required value={params.lastName} onChange={onLastNameChange}/>
            </Grid>
            <Grid item xs={12}>
              <Input variant="dark" id="email" type="email" label="Email" fullWidth value={params.email} onChange={onEmailChange}/>
            </Grid>
            <Grid item xs={12}>
              <Input variant="dark" id="number" type="number" label="Teléfono" fullWidth required value={params.phoneNumber} onChange={onPhoneNumberChange}/>
            </Grid>
            <Grid item xs={12}>
              <Input variant="dark" id="message" label="Mensaje" multiline rows={4} fullWidth value={params.message} onChange={onMessageChange}/>
            </Grid>
            <Grid item xs={12} className="flex justify-center items-center pt-10">
              <Button type="submit">Enviar</Button>
              <div className='text-white flex text-center justify-center items-center h-full px-4'> o </div>
              <ReactWhatsapp className="border-none hover:bg-green-700 h-[3rem] flex items-center bg-green-500 text-white rounded-[10px] shadow-sm" element="button" number="+52 7711580540" message="Hola, me gustaría conocer más sobre ustedes."><WhatsAppIcon fontSize='large'/></ReactWhatsapp>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </div>
  );
}

export default ContactForm;