import React, { useState, useEffect } from 'react';
import { default as MuiCarousel } from 'react-simply-carousel';

import { styled } from '@mui/material/styles';
import carouselStyles from './CarouselStyles';
import optionsStyles from '../Options/OptionsStyles';

import Button from '../Button/Button';
import Separator from '../Separator/Separator';
import dryvan from '../../images/dryvan.png';
import reefer from '../../images/reefer.png';
import flatbed from '../../images/flatbed.png';
import lowboy from '../../images/lowboy.png';
import dolly from '../../images/dolly.png';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

type CarouselProps = {};

const StyledCarousel = styled('section')<CarouselProps>(({ theme }) => carouselStyles);

const StyledCard = styled('div')<{}>(({ theme }) => optionsStyles);

const Carousel:React.FC<CarouselProps> = (props) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);

  const [ state, setState ] = useState(-1);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);


  const handleMouseEnter = (idx: number) => {
    setState(idx);
  };

  const handleMouseLeave = (idx: number) => {
    setState(idx);
  };

  const isMobile = width <= 1024;

  return (
    <StyledCarousel className='flex flex-col items-center mb-16 lg:mb-56 lg:hidden'>
      <div className="pt-[6rem] lg:pt-[8rem] text-4xl lg:text-6xl font-semibold text-center pb-[4rem] lg:pb-[1rem] px-6">Calidad, Rapidez y Atención</div>
      <div className="flex flex-col align-middle justify-center my-4">
        <Separator className="mb-10"/>
        <div className="text-xl max-w-[54rem] text-center mx-8 md:mx-24 mb-8">En lugar de hacer una inversión inicial significativa para adquirir el equipo, simplemente pagas una tarifa de renta regular.
        Esto te permite conservar tu capital para otras necesidades comerciales o inversiones.</div>
      </div>
      <MuiCarousel
        containerProps={{
          className: "carousel-container"
        }}
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={isMobile ? 1 : 2}
        centerMode
        itemsToScroll={1}
        forwardBtnProps={{
          children: <KeyboardArrowRightIcon />,
          className: `${isMobile && 'hidden'} carousel-arrow carousel-right-arrow`
        }}
        backwardBtnProps={{
          children: <KeyboardArrowLeftIcon />,
          className: `${isMobile && 'hidden'} carousel-arrow carousel-left-arrow`
        }}
        dotsNav={{
          show: true,
          itemBtnProps: {
            className: 'carousel-dots',
          },
          activeItemBtnProps: {
            className: 'carousel-dots carousel-active-dot'
          }
        }}
        responsiveProps={[
          {
            itemsToShow: 2,
            itemsToScroll: 1,
            minWidth: 1024,
          },
        ]}
        speed={400}
        easing="ease-in-out"
      >
        <div className='w-[25rem] p-8 flex md:w-[35rem] lg:w-[25rem]'>
          <StyledCard className='!m-auto sm:!w-full' onMouseEnter={() => handleMouseEnter(0)} onMouseLeave={() => handleMouseLeave(-1)}>
            <img className="w-40" src={dryvan}/>
            <div className='option-card-title'>Cajas secas</div>
            <div className='option-card-subtitle'>Equipos de excelente calidad.</div>
            <Button href="/location">Ver más</Button>
          </StyledCard>
        </div>
        <div className='w-[25rem] p-8 flex md:w-[35rem] lg:w-[25rem]'>
          <StyledCard className='!m-auto sm:!w-full' onMouseEnter={() => handleMouseEnter(1)} onMouseLeave={() => handleMouseLeave(-1)}>
            <img className="w-40" src={reefer}/>
            <div className="option-card-title">Termos</div>
            <div className="option-card-subtitle">Rentalo en tiempo record.</div>
            <Button href="/location">Ver más</Button>
          </StyledCard>
        </div>
        <div className='w-[25rem] p-8 flex md:w-[35rem] lg:w-[25rem]'>
          <StyledCard className='!m-auto sm:!w-full' onMouseEnter={() => handleMouseEnter(2)} onMouseLeave={() => handleMouseLeave(-1)}>
            <img className="w-40" src={flatbed}/>
            <div className="option-card-title">Plataformas</div>
            <div className="option-card-subtitle">Nuestro servicio al cliente siempre a tu disposición.</div>
            <Button href="/location">Ver más</Button>
          </StyledCard>
        </div>
        <div className='w-[25rem] p-8 flex md:w-[35rem] lg:w-[25rem]'>
          <StyledCard className='!m-auto sm:!w-full' onMouseEnter={() => handleMouseEnter(2)} onMouseLeave={() => handleMouseLeave(-1)}>
            <img className="w-40" src={lowboy}/>
            <div className="option-card-title">Camas bajas</div>
            <div className="option-card-subtitle">Transporta maquinaria pesada.</div>
            <Button href="/location">Ver más</Button>
          </StyledCard>
        </div>
        <div className='w-[25rem] p-8 flex md:w-[35rem] lg:w-[25rem]'>
          <StyledCard className='!m-auto sm:!w-full' onMouseEnter={() => handleMouseEnter(2)} onMouseLeave={() => handleMouseLeave(-1)}>
            <img className="w-40" src={dolly}/>
            <div className="option-card-title">Dollys</div>
            <div className="option-card-subtitle">Modelos recientes.</div>
            <Button href="/location">Ver más</Button>
          </StyledCard>
        </div>
      </MuiCarousel>
    </StyledCarousel>
  )
}

export default Carousel;