import { Theme } from "@mui/material";
import { colors, hoverColors } from "../../constants/colors";

const styles = {
  '& .MuiSvgIcon-root': {
    color: colors.white,
    fontSize: '3rem'
  }
};

const footerStyles: any = (theme?: Theme) => styles;

export default footerStyles;