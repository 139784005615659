import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import optionsStyles from './OptionsStyles';

import dryvan from '../../images/dryvan.png';
import reefer from '../../images/reefer.png';
import flatbed from '../../images/flatbed.png';
import lowboy from '../../images/lowboy.png';
import dolly from '../../images/dolly.png';
import Button from '../Button/Button';
import Carousel from '../Carousel/Carousel';
import Separator from '../Separator/Separator';

type OptionsProps = {};

const StyledCard = styled('div')<OptionsProps>(({ theme }) => optionsStyles);

const Options:React.FC<OptionsProps> = (props) => {
  const [ state, setState ] = useState(-1);

  const handleMouseEnter = (idx: number) => {
    setState(idx);
  };

  const handleMouseLeave = (idx: number) => {
    setState(idx);
  };

  return (
    <div>
      <section className="flex-col items-center hidden lg:flex" id="options-section">
        <div className="container mt-[6rem] lg:mt-[8rem] text-4xl lg:text-6xl font-semibold text-center mb-[4rem] lg:mb-[1rem]">Calidad, Rapidez y Atención</div>
        <div className="flex flex-col align-middle justify-center my-4">
          <Separator className="mb-10"/>
          <div className="text-xl max-w-[54rem] text-center mx-8 md:mx-24 mb-8">En lugar de hacer una inversión inicial significativa para adquirir el equipo, simplemente pagas una tarifa de renta regular.
          Esto te permite conservar tu capital para otras necesidades comerciales o inversiones.</div>
        </div>
        <div className="container flex flex-col items-center justify-center lg:items-stretch lg:flex-row flex-wrap">
          <StyledCard onMouseEnter={() => handleMouseEnter(0)} onMouseLeave={() => handleMouseLeave(-1)}>
            <img className="w-40" src={dryvan}/>
            <div className='option-card-title'>Cajas secas</div>
            <div className='option-card-subtitle'>Equipos de excelente calidad.</div>
            <Button href="/location">Ver más</Button>
          </StyledCard>
          <StyledCard onMouseEnter={() => handleMouseEnter(1)} onMouseLeave={() => handleMouseLeave(-1)}>
            <img className="w-40" src={reefer}/>
            <div className="option-card-title">Termos</div>
            <div className="option-card-subtitle">Rentalo en tiempo record.</div>
            <Button href="/location">Ver más</Button>
          </StyledCard>
          <StyledCard onMouseEnter={() => handleMouseEnter(2)} onMouseLeave={() => handleMouseLeave(-1)}>
            <img className="w-40" src={flatbed}/>
            <div className="option-card-title">Plataformas</div>
            <div className="option-card-subtitle">Nuestro servicio al cliente siempre a tu disposición.</div>
            <Button href="/location">Ver más</Button>
          </StyledCard>
          <StyledCard onMouseEnter={() => handleMouseEnter(2)} onMouseLeave={() => handleMouseLeave(-1)}>
            <img className="w-40" src={lowboy}/>
            <div className="option-card-title">Camas bajas</div>
            <div className="option-card-subtitle">Transporta maquinaria pesada.</div>
            <Button href="/location">Ver más</Button>
          </StyledCard>
          <StyledCard onMouseEnter={() => handleMouseEnter(2)} onMouseLeave={() => handleMouseLeave(-1)}>
            <img className="w-40" src={dolly}/>
            <div className="option-card-title">Dollys</div>
            <div className="option-card-subtitle">Modelos recientes.</div>
            <Button href="/location">Ver más</Button>
          </StyledCard>
        </div>
      </section>
      <Carousel />
    </div>
  )
}

export default Options;