import { Theme } from "@mui/material";
import { colors, hoverColors } from "../../constants/colors";

const styles = {
  paddingLeft: '4rem',
  paddingRight: '4rem',
  paddingBottom: '8rem',
  maxWidth: '32rem',
  '& .MuiPaper-root': {
    color: colors.white,
    backgroundColor: '#696969',
    borderLeft: '2px solid ' + colors.primary,
    
  },
  '& .MuiAccordionSummary-content': {
    fontWeight: '600'
  },
  '& .MuiAccordionDetails-root': {
    backgroundColor: '#676767'
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: colors.primary,
    '&.Mui-expanded': {
      color: 'white'
    }
  }
};

const accordionStyles: any = (theme?: Theme) => styles;

export default accordionStyles;