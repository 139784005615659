import React from "react";
import { styled } from '@mui/material/styles';
import DryVanBg from '../../images/dryvanimage.jpeg';
import Separator from "../Separator/Separator";
import Button from "../Button/Button";

type SellProps = {

};

const Sell: React.FC<SellProps> = () => {
  return (
    <section className="relative flex items-center justify-center h-[40rem]">
      <img src={DryVanBg} className="h-full w-full object-cover"/>
      <div className="absolute top-0 right-0 left-0 bottom-0 flex flex-col-reverse lg:flex-row justify-start">
        <div className="flex items-center w-full lg:w-1/2 h-full bg-[#000000de]">
          <div className="p-16">
            <div className="text-4xl sm:text-6xl text-white font-semibold max-w-[20rem]">¿Prefieres comprar?</div>
            <Separator className="!block py-12"/>
            <div className="text-white">Estamos comprometidos con ofrecerle la opción que mejor se adapte a sus necesidades. Contáctenos para verificar
              disponibilidad y con gusto le atenderemos.
            </div>
            <Button className="mt-12" href="/location">
              Ver más
            </Button>
          </div>
        </div>
        <div className="lg:block h-0 lg:h-full border-solid border-secondary border-[4px] border-y-0" />
        <div className="lg:block h-0 lg:h-full border-solid border-primary border-[12px] border-y-0" />
      </div>
    </section>
  );
};

export default Sell;