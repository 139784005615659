import { Theme } from "@mui/material";

const styles = {
  backgroundColor: '#d6d6d6',
  '& #benefit-card': {
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px'
  }
};

const benefitsStyles: any = (theme?: Theme) => styles;

export default benefitsStyles;